import React from 'react';
import styled from 'styled-components';
export const ContactStyles = styled.div`
font-size: var(--paragraphFontSize);
  margin-left: 2vw;
  margin-right: 2vw;
  max-width: 450px;
  margin-top: var(--headerHeight);
  // text-align: center;
  h1 {
    // text-align: center;
  }
`;

export default function ContactPage() {
    return(
        <>
        <ContactStyles>
            <form name="contact" action="/success" method="POST" netlify-honeypot="bot-field" netlify>
                <input type="hidden" name="bot-field" />
                <p>
                    <label>Name <input 
                    required type="text" name="name" /></label>
                </p>
                <p>
                    <label>Email <input type="email" name="email" /></label>
                </p>
                <p>
                    <button type="submit">Send</button>
                </p>
            </form>
        </ContactStyles>
        </>
    )
}